<template>
	<div class="bl-input-multiple bl-file-input" :class="{'multi': multi, 'bl-input__value': ((multi && file.length) || (!multi && file)) && !dragOver, active: active, errored: field.isErrored() && field.getTouched()}" @drop.prevent="dropHandler($event)" @dragover="dragOver = true" @dragleave="dragOver = false">
		<div class="dragOver" v-if="dragOver">
			<div style="position: relative; width: 45px; height: 45px;">
				<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path fill="var(--bl-on-primary)" d="M 11 40 Q 9.8 40 8.9 39.1 Q 8 38.2 8 37 V 29.85 H 11 V 37 Q 11 37 11 37 Q 11 37 11 37 H 37 Q 37 37 37 37 Q 37 37 37 37 V 29.85 H 40 V 37 Q 40 38.2 39.1 39.1 Q 38.2 40 37 40 Z H 25.5 Z"/></svg>
				<svg xmlns="http://www.w3.org/2000/svg" class="arrow" height="48" width="48"><path fill="var(--bl-on-primary)" d="M 24 32.35 L 14.35 22.7 L 16.5 20.55 L 22.5 26.55 V 8 H 25.5 V 26.55 L 31.5 20.55 L 33.65 22.7 Z"/></svg>
			</div>
			<div>Drop your files for "{{ field.label }}"</div>
		</div>
		<label @click="upload()">{{ field.label }}</label>
		<div :class="{dropDownOpened: showList}" class="bl-input-multiple-content" v-if="!multi" @contextmenu="preview(file, $event)">
			<div v-if="file && !dragOver" class="bl-formchip">
				<div class="file-loader" v-if="(file.progress || file.progress === 0) && file.progress !== 100"></div>
				<div class="progressBar" v-if="file.progress || file.progress === 0" :class="{done: file.progress == 100}" :style="{width: file.progress + '%'}"></div>
				<img v-if="file.icon" :src="'https://static.mixsuite.fr/file_icons/' + file.icon + '.svg'" />
				<span>{{ file.name }}</span>
			</div>
			<input type="text" v-model="search" @click="upload()" ref="dummySearchBar" @focus="upload(); $refs.dummySearchBar.blur()" :tabindex="tabindex" />
		</div>
		<div :class="{dropDownOpened: showList}" class="bl-input-multiple-content multi" v-if="multi">
			<div class="bl-formchip" v-for="f in file" :key="f.id" @contextmenu="preview(f, $event)">
				<div class="file-loader" v-if="(f.progress || f.progress === 0) && f.progress !== 100"></div>
				<div class="progressBar" v-if="f.progress || f.progress === 0" :class="{done: f.progress == 100}" :style="{width: f.progress + '%'}"></div>
				<img v-if="f.icon" :src="'https://static.mixsuite.fr/file_icons/' + f.icon + '.svg'" />
				<span>{{ f.name }}</span>
				<icon style="margin-left: 2px;" @click="renameFile(f)">edit</icon>
				<icon @click="removeFile(f)">delete</icon>
			</div>
			<input type="text" v-model="search" @click="upload()" ref="dummySearchBar" @focus="upload(); $refs.dummySearchBar.blur()" :tabindex="tabindex" />
		</div>
		<span class="suffix material-icons" v-if="!file || dragOver || multi" @click="upload()">download_for_offline</span>
		<span class="suffix material-icons" v-if="!multi && (file && !dragOver)" @click="renameFile(file)" style="margin-right: 30px;">edit</span>
		<span class="suffix material-icons" v-if="!multi && (file && !dragOver)" @click="removeFile()">delete</span>
	</div>
</template>

<script>
import { UploadHelpers } from 'FileBundle'
import { EventEmitter, Dialog } from 'InterfaceBundle'

export default {
	name: 'BlFormFieldFile',
	props: ['field', 'tabindex'],
	data() {
		return {
			model: this.field.value,
			file: null,
			active: false,
			dragOver: false,
			multi: false
		}
	},
	created() {
		this.uploadingEvent = new EventEmitter()
		if(this.field.options.multi) this.multi = true
		if(this.model) this.file = JSON.parse(JSON.stringify(this.model))//Avoid metadata duplication in subforms
		if(this.multi && !this.file) this.file = []
		if(this.field.root) this.onBeforeSubmitSub = this.field.root.form.onBeforeSubmit.subscribe(val => {
			if(this.fileUploadingCount) val.events.push(this.uploadingEvent)
		})
		this.fileUploadingCount = 0
	},
	unmounted() {
		if(this.onBeforeSubmitSub) this.onBeforeSubmitSub.unsubscribe()
	},
	methods: {
		removeFile(file = null) {
			if(this.multi) this.file = this.file.filter(f => f != file)
			else this.file = null
			this.field.setValue(this.file)
		},
		dropHandler(event) {
			this.dragOver = false
			UploadHelpers.dropHandler(
				event,
				this.multi,
				fileOption => {
					this.fileUploadingCount++
					if(this.multi) this.file.push(fileOption)
					else this.file = fileOption
				},
				() => this.$forceUpdate(), resp => this.handleFile(resp), null, this.field.options.asBase64, false)
		},
		upload() {
			if(this.active) return
			this.active = true
			this.field.setTouched()
			UploadHelpers.upload(
				this.multi, //multi
				this.field.options.mimeType, //mimetype
				(success, fileOption) => { //fileCb when file is added with window dialog
					if(success) {
						this.fileUploadingCount++
						if(this.multi) this.file.push(fileOption)
						else this.file = fileOption
					}
					this.active = false
				}, 
				() => this.$forceUpdate(), //progressCb
				resp => this.handleFile(resp), //loadCb, when file is uploaded in tmp
				null, // location
				this.field.options.asBase64, //dl as b64
				false // auto handle(skip tmpName)
			)
		},
		handleFile(resp) {
			this.fileUploadingCount--
			if(this.fileUploadingCount == 0) this.uploadingEvent.emit()
			if(!resp.error && this.file) {
				this.$forceUpdate()
				this.setFileValue()
			}
		},
		renameFile(file) {
			let nameParts = file.name.split('.')
			let extension = ''
			if(nameParts.length > 0) {
				extension = '.' + nameParts[nameParts.length - 1]
				nameParts.pop()
			}
			Dialog.prompt({
				accept: this.$t('file.explorer.dialogRenameFile.accept'),
				cancel: this.$t('file.explorer.dialogRenameFile.cancel'),
				required: false,
				closeButton: true,
				promptValue: nameParts.join('.'),
				promptLabel: this.$t('file.explorer.dialogRenameFile.promptLabel')
			}).then(resp => {
				file.name = resp + extension
				this.setFileValue()
			})
			return false
		},
		setFileValue() {
			if(this.multi) this.field.setValue(this.file.map(f => {
				let ret = {name: f.name}
				if(f.tmpName) {
					ret.tmpPath = f.tmpName
					ret.lastModified = f.lastModified
				}
				if(f.id) ret.id = f.id
				return ret
			}))
			else {
				if(this.field.options.asBase64) this.field.setValue({name: this.file.name, base64: this.file.base64})
				else this.field.setValue({name: this.file.name, tmpPath: this.file.tmpName, lastModified: this.file.lastModified})
			}
		},
		preview(file, e) {
			if(!file.embededViewerUrl) return
			e.preventDefault()
			Dialog.custom({
				fullScreen: true,
				closeButton: false,
				component: 'BlFileViewDialog',
				componentProps: {id: file.id, name: file.name, icon: file.icon, embededViewerUrl: file.embededViewerUrl}
			}).then(() => {}).catch(() => {})
		}
	}
}
</script>

<style scoped lang="scss">
div.bl-input-multiple.errored {
	div.bl-input-multiple-content {
		box-shadow: 0 0 0 2px var(--bl-error);
	}

	label {
		color: var(--bl-error);
	}
}

div.bl-input-multiple.active {
	div.bl-input-multiple-content {
		box-shadow: 0 0 0 2px var(--bl-primary);
	}

	label {
		color: var(--bl-primary);
	}
}

div.bl-input-multiple {
	position: relative;

	label {
		color: var(--bl-legend);
		cursor: text;
		position: absolute;
		margin: 8px 0 0 5px;
		transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
		padding-right: 4px;
		padding-left: 2px;
	}

	div.bl-input-multiple-content {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		box-shadow: 0 0 0 1px var(--bl-border);
		transition: box-shadow .2s cubic-bezier(0.4,0.0,0.2,1);
		border-radius: var(--bl-border-radius);

		div.bl-formchip {
			position: relative;
			border-radius: var(--bl-border-radius);
			background-color: var(--bl-border);
			padding: 3px 4px 2px 6px;
			margin-left: 4px;
			margin-top: -1px;
			display: flex;

			.progressBar {
				opacity: .2;
				background-color: var(--bl-primary);
				position: absolute;
				height: 100%;
				margin-top: -3px;
				margin-left: -6px;
				border-radius: var(--bl-border-radius);
				z-index: 0;
				transition: opacity .4s, width .1s;
				transition-timing-function: linear;
			}

			.progressBar.done {
				opacity: 0;
				cursor: default;
				pointer-events: none;
			}
		}

		div.bl-formchip > span {
			z-index: 1;
			position: relative;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: block;
		}

		input {
			cursor: pointer;
			border: none;
			padding: 8px;
			font-family: Roboto;
			border-radius: var(--bl-border-radius);
			font-size: 14px;
			outline: none;
			background-color: var(--bl-surface);
			color: var(--bl-on-surface);
			flex: 1 1 0%;
		}
	}
}

div.bl-input-multiple.bl-input__value {
	label {
		background-color: var(--bl-surface);
		font-size: 10.5px;
		margin-top: -7px;
	}
}

//Single file input CSS
div.bl-input-multiple div.bl-input-multiple-content {
	padding: 0;

	div.bl-formchip {
		width: 100%;
		margin-left: 0;
		background-color: transparent;

		.progressBar {
			margin-top: -9px;
			height: 33px;
			cursor: wait;
		}
	}
}

div.bl-input-multiple.bl-input__value input {
	display: none;
}

span.suffix {
	cursor: pointer;
	top: 5px;
	right: 4px;
	position: absolute;
	color: var(--bl-legend);
	font-size: 23px;
	transition: background-color .2s;
}

span.suffix::after {
	border-radius: 50%;
	content: ' ';
	background-color: var(--bl-on-surface);
	width: calc(100% + 6px);
	height: calc(100% + 6px);
	position: absolute;
	left: -3px;
	top: -3px;
	opacity: 0;
	transition: opacity .2s;
}

span.suffix:hover::after {
	opacity: .05;
}

.dragOver {
	width: 100%;
	position: absolute;
	display: flex;
	align-items: center;
	border-radius: var(--bl-border-radius);
	height: 100%;
	justify-content: center;
	font-weight: 500;
	background-color: var(--bl-primary);
	color: var(--bl-on-primary);
	z-index: 4;
	font-size: 16px;
	box-shadow: 0 0 0 3px var(--bl-primary);
	pointer-events: none;

	svg {
		position: absolute;
		transform: scale(.6);
	}

	svg.arrow {
		animation: dragOverAnimateArrow 1.5s infinite cubic-bezier(0.42, 0.0, 0.58, 1.0);
	}
}

@keyframes dragOverAnimateArrow {
	0% {
		opacity: 1;
		transform: scale(.6) translateY(-10px);
	}

	50% {
		opacity: .4;
		transform: scale(.6) translateY(0);
	}

	100% {
		opacity: 1;
		transform: scale(.6) translateY(-10px);
	}
}


.bl-input-multiple .bl-input-multiple-content.multi {
	padding: 1px 0 5px 0;

	.bl-formchip {
		width: auto;
		align-items: center;
		border-radius: var(--bl-border-radius);
		background-color: var(--bl-border);
		padding: 3px 4px 2px 6px;
		margin-left: 4px;
		margin-top: 4px;

		icon {
			float: right;
			cursor: pointer;
			font-size: 14px;
			padding: 2px;
			color: var(--bl-legend);
			border-radius: var(--bl-border-radius);
			transition: background-color .2s;
		}

		icon:hover {
			background-color: var(--bl-border);
		}

		.progressBar {
			margin-top: 0;
			height: 23px;
		}
	}

	input[type="text"] {
		display: block;
		flex: 1;
		padding: 7px 5px 3px 5px;
		min-width: 100px;
	}
}

.bl-input-multiple {
	margin-top: 4px;
}

.bl-input-multiple.bl-input__value:not(.multi) label {
	margin-top: -5px;
}

.bl-input-multiple .bl-input-multiple-content:not(.multi) {
	padding: 0;
	min-height: 33px;

	.bl-formchip {
		outline: none;
		margin-top: 1px;

		img {
			width: 14px;
			margin-right: 5px;
			margin-top: 1px;
			float: left;
			border-radius: 3px;
		}
	}
}

.file-loader {
	width: 18px;
	height: 18px;
	border: 2px solid var(--bl-surface);
	border-bottom-color: var(--bl-primary);
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: file-loader-rotation 1s linear infinite;
	margin-right: 5px;
	float: left;
}

.bl-input-multiple .bl-input-multiple-content.multi .bl-formchip {
	font-size: 12px;
	background-color: var(--bl-background);
	outline: none;

	img {
		width: 12px;
		margin-right: 4px;
		border-radius: 3px;
	}
}

@keyframes file-loader-rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
} 
</style>